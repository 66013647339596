import axios from "./axios.js"

// const token = "1|ku7lIj2vaSsk0iYkGbytMxN5FCu6sdojX6fM2fG0";


export class Country {

    static getCountries() {
        return axios.get("/country");

    }
    static getCountry(countryId) {
        let defaultURL = `/country/${countryId}`;
        return axios.get(defaultURL);
    }
    static createCountry(country) {
        let defaultURL = `/country`;
        // return axios.post(defaultURL,country);
        try {
            return axios.post(defaultURL, country);
        } catch (error) {
            console.log("this is error ", error)
            if (error.response.status === 422) {
                return error.response.data.errors

            }
            // console.log(errors.value.name);
        }
    }
    static updateCountry(country, countryId) {
        let defaultURL = `/country/${countryId}`;
        return axios.put(defaultURL, country);
    }
    static deleteCountry(countryId) {
        let defaultURL = `/country/${countryId}`;
        return axios.delete(defaultURL);
    }

}