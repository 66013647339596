<template>

    <div class="container mb-3">
        <div class="row">
            <div class="row">
                <div class="col-md-6">
                    <h3>Country Master</h3>
                </div>
                <div class="col-md-6">
                    <!-- <pre>{{ form }} {{ query }}</pre> -->
                    <button class="btn btn-primary " v-if="!seen" @click="seen = !seen" style="float: right;"
                        id="btnAdd">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Country</button>
                    <button class="btn btn-primary" v-if="seen" @click="seen = !seen" style="float: right;"
                        id="btnHide">
                        <i class="fa fa-minus-circle" aria-hidden="true"></i>
                        Hide</button>
                </div>
            </div>

        </div>

        <div class="row" id="mainform" v-if="seen">
            <div class="col">

                <form @submit="onSubmit" class="needs-validation Country-mas">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="countryName" class="form-label">Country Name </label>
                            <input type="text" v-model="form.countryName" class="form-control has-validation"
                                id="countryName" placeholder="Company Name" max="100" maxlength="100" required
                                autocomplete="off">
                            <div v-if="errors.countryName" class="invalid-feedback">{{ errors.countryName }}</div>
                        </div>
                        
                        <div class="btn-bottom">
                            <input type="submit" :value="btnText" class="btn  btn-primary" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="container">
        <hr />
        <div class="table-responsive">
            <table class="table" ref="countryTable">
                <thead>
                    <tr>
                        <th>#ID</th>
                        <th>Country Name</th>                        
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="country in countries" :key="country.id">
                        <td>{{ country.id }}</td>
                        <td><b>{{ country.countryName }}</b></td>
                            
                        
                        <td> 
                            <button class="btn btn-success btn-sm" @click="editdata(country.id)"><i
                                    class="fa fa-pencil" aria-hidden="true"></i>
                                Edit</button> <button class="btn btn-danger btn-sm"
                                @click="deletedata(country.id)"><i class="fa fa-trash" aria-hidden="true"></i>
                                Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col">
                <Spinner />
            </div>
        </div>
    </div>
   
</template>

<script>


import { Country } from '../services/Country';
import Spinner from '../components/Spinner.vue';
import $ from "jquery";
import dt from "datatables.net";


$(function () {
    //  alert('ok');
    // this.dt = $('#cityTable').DataTable();
});
export default {
    name: 'CountryMaster',
    components: {        
        Spinner

    },
    data() {
        return {
            form: {
                id: '',
                countryName: '',               

            },
            countries: [],            
            seen: false, 
            loading : false,           
            dt: dt,            
            btnText: 'Save',            
            errors: [],
            
        }
    },
    async created() {        
        try {
            this.loading = true;
            let response = await Country.getCountries();
            // console.log(response.data);
            this.countries = response.data;
            this.loading = false
        }
        catch (error) {
            this.errors = error;
            this.loading = false;
        }

    },
    updated() {

    },
    mounted() {
        this.dt = $(this.$refs.countryTable).DataTable();
        
    },
    watch: {
        countries() {
            this.dt.destroy();
            this.$nextTick(() => {
                this.dt = $(this.$refs.countryTable).DataTable()
            })
        }

    }, methods: {
        checkValidations() {
            var countryName = document.getElementById("countryName");
            

            if (this.errors.countryName) countryName.classList.add("is-invalid"); else countryName.classList.add("is-valid");
            
        },
        removeValidations() {
            var countryName = document.getElementById("countryName");
            
            countryName.classList.remove("is-invalid");
            countryName.classList.remove("is-valid");
            
        },
        async onSubmit(e) {
            e.preventDefault()
            if (!this.form.countryName) {
                alert('Please Enter Country name')
                return
            }



            // const response = await Country.createCountry(form);
            if (this.btnText === 'Save') {
                try {
                    this.loading = true;
                    const response = await Country.createCountry(this.form);
                    // console.log(response);
                    if (response.data.status == 'success') {
                        this.$swal(
                            'Country Created ',
                            response.data.status,
                            'success'
                        );
                    }
                    this.form.countryName = "";                    
                    
                    this.loading = false;
                    this.btnText = "Save";
                    this.removeValidations();
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        // console.log(error.response.data.errors.name);
                        this.errors = error.response.data.errors;
                        this.checkValidations();

                    }
                    this.loading = false;
                    // console.log(errors.value.name);
                }
            } else {
                // alert('update');
                try {
                    this.loading = true;
                    const response = await Country.updateCountry(this.form, this.form.id);
                    // console.log(response);
                    if (response.data.status == 'success') {
                        this.$swal(
                            'Country Updated ',
                            response.data.status,
                            'success'
                        );
                    }
                    this.form.countryName = "";                    
                    this.loading = false;
                    this.btnText = "Save";
                    this.removeValidations();

                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        // console.log(error.response.data.errors.name);
                        this.errors = error.response.data.errors;
                        this.checkValidations();
                    }
                    this.loading = false;
                    // console.log(errors.value.name);
                }

            }
            this.loading = true
            let response = await Country.getCountries();
            // console.log(response.data);
            this.countries = response.data;
            this.loading = false;


        },
        async editdata(id) {
            // alert(id);
            this.loading = true;
            this.btnText = "Update";
            const response = await Country.getCountry(id);
            this.form.id = id;
            this.form.countryName = response.data.countryName;
            
            // console.log(response.data.name);
            // this.btnText = "Save";
            this.seen = true;
            this.loading = false;
        },
        async deletedata(id) {
            let result = await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            // alert(result.isConfirmed);
            if (result.isConfirmed) {
                this.loading = true;
                const resDel = await Country.deleteCountry(id)
                // console.log(resDel);
                if (resDel.status == 204) {
                    this.$swal(
                        'Country Deleted',
                        resDel.data.status,
                        'success'
                    );
                }

            }

            // alert(id);

            // this.loading = true;
            let response = await Country.getCountries();
            this.countries = response.data;
            this.loading = false;
        },
        hideFrom() {

        }
    },
}
</script>
<style scoped>
.Country-mas {
    background: #fbfbfb;
    border: solid 1px #d2d2d2;
    padding: 30px;
    margin: 20px 0px;
}

.Country-mas .form-control {
    padding: 12px 10px;
    border-radius: 0;

}

.multiselect {
    border-radius: 0;
    padding: 4px 10px;
}

.btn-bottom {
    text-align: center;
}

.Country-mas .btn.btn-primary {
    border-radius: 3px;
    padding: 10px 25px;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}

.table>thead {
    vertical-align: bottom;
    background: lightskyblue;
}

tbody tr td {
    border-right: 1px solid #a7daf9;
}

tbody tr td:first-child {
    border-left: 1px solid #a7daf9;
}


tr.odd {
    background-color: #FFF;
    vertical-align: middle;
}

tr.even {
    background-color: #e5f5ff;
    vertical-align: middle;
}
</style>